@import "../../../../assets/css/variables.scss";
.working-conditions {
	width: 100%;
	.heading {
		background: $grey-bg1;
		display: flex;
		width: 100%;
		padding: 15px 20px;
		font-weight: $fw-xxbold;
		font-size: 17px;
		line-height: 20px;
		letter-spacing: 0.708333px;
		color: $grey-dark1;
		margin-bottom: 15px;
	}
	.selection-wrapper {
		display: flex;
		justify-content: space-between;
		.sub-head {
			background: $grey31;
			display: flex;
			width: 100%;
			padding: 10px 20px;
			font-size: 16px;
			color: $grey-dark1;
			margin-bottom: 15px;
		}
		.wc-details {
			display: block;
			background: $grey30;
			font-size: 14px;
			padding: 28px 30px;
			.text-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				.info {
					font-weight: $fw-reg;
					font-size: 14px;
					letter-spacing: -0.25px;
					padding: 7px 0;
					text-align: left;
					&:last-child {
						text-align: right;
						font-weight: 700;
						max-width: 70%;
					}
				}
			}
		}
		.wc-details-hide {
			display: block;
			background: $grey30;
			font-size: 14px;
			padding: 15px 20px;
			.text-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				.info {
					font-weight: $fw-reg;
					font-size: 14px;
					letter-spacing: -0.25px;
					padding: 7px 0;
					text-align: left;
					&:last-child {
						text-align: right;
						font-weight: 700;
						max-width: 70%;
					}
				}
			}
		}
		.wc-form {
			display: block;
			padding: 0 0 0 40px;
			text-align: left;
			.form-control {
				border-radius: 0;
				box-sizing: border-box;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 12px 12px 12px 16px;
				gap: 8px;
				height: 48px;
				background: $white;
				border: 1px solid $grey2;
				flex: none;
				order: 1;
				align-self: stretch;
				flex-grow: 0;
				&:focus {
					border-color: $grey2;
					outline: 0;
					box-shadow: none;
				}
				&::-webkit-input-placeholder {
					color: $grey21;
					font-style: italic;
				}
			}
			.form-group {
				margin-bottom: 8px;
				label {
					font-weight: 700;
					font-size: 16px;
					margin-bottom: 5px;
				}
				.mandatory {
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background: $red1;
					display: inline-block;
					position: relative;
					top: -2px;
				}
				.form-text {
					color: $grey9;
					font-size: 12px;
					font-weight: 500;
				}
				.error {
					color: $red1;
				}
				input.form-control.error-message,
				select.form-control.error-message {
					border: 1px solid $red1;
				}
				.form-control {
					appearance: none;
				}
				.angle-down-arrow {
					float: right;
					margin-top: -40px;
					margin-right: 0.5em;
					pointer-events: none;
	      			background-color: transparent;
					color: $grey34;
				}
				.angle-down-icon {
					width: 1.9em;
					height: 1.9em;
					stroke-width: 0;
				}
			}
		}
	}
}
@media only screen and (max-width: 820px) {
	.working-conditions {
		padding-bottom: 10px;
		.selection-wrapper {
			display: block;
			.wc-form {
				padding: 25px 0 100px;
			}
			.wc-details {
				padding: 20px;
				.text-wrapper .info:last-child {
					padding-left: 15px;
				}
			}
			.sub-head {
				margin-bottom: 0;
			}
			.col-md-7.col-sm-12 {
				width: 100%;
			}
			.col-md-5.col-sm-12 {
				width: 100%;
			}
			.angle-chevron-down {
				float: right;
				margin-left: auto;
			}
			.angle-chevron-down-icon {
				height: 2em;
				width: 2em;
			}
			.wc-details-hide {
				display: none;
			}
			.pt-5 {
				padding-top: 0 !important;
			}
		}
	}
}
@media only screen and (min-width: 821px) {
	.working-conditions {
		.selection-wrapper {
			.angle-chevron-down {
				display: none;
			}
		}
	}
}