@import "../../../../assets/css/variables.scss";
.contaminant {
	width: 100%;
	.btn-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.heading {
		background: $grey-bg1;
		display: flex;
		width: 100%;
		padding: 15px 20px;
		font-weight: $fw-xxbold;
		font-size: 17px;
		line-height: 20px;
		letter-spacing: 0.708333px;
		color: $grey-dark1;
		margin-bottom: 10px;
	}
	.sub-head {
		display: flex;
		padding: 0;
		margin: 0;
		font-weight: $fw-xxbold;
		font-size: 16px;
		line-height: 20px;
		color: $grey-dark1;
	}
	.form-control {
		display: block;
		width: 5rem;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		background: transparent;
		background-clip: padding-box;
		border: 1px solid $grey2;
		appearance: none;
		border-radius: 0px;;
		&:focus {
			border-color: $grey2;
			outline: 0;
			box-shadow: none;
		}
	}
	.angle-down-arrow {
        position: relative;
		right: 35px;
		z-index: -1;
		max-width: 0px;
    }
    .angle-down-icon {
        width: 2rem;
        height: 2rem;
        color: $grey34;
    }
	.warning-icon {
		display: none;
	}
}
.containment-table {
	width: 100%;
	.panel-body {
	  padding: 0;
	  margin-top: 20px;
	  border-top: 1px solid $grey2;
	  border-right: 1px solid $grey2;
	  border-left: 1px solid $grey2;
	  border-bottom: 0px solid $grey2;
	  .table-head {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: normal;
		align-items: normal;
		align-content: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		border-bottom: 1px solid $grey2;
	  }
	  .table-items {
		display: flex;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;
		width: 33%;
		padding: 10px 0 10px 15px;
		border-right: 1px solid $grey2;
		&:first-child {
		  width: 60px;
		}
		&:last-child {
		  border-right: none;
		}
	  }
	  a.sort-by {
		padding-right: 18px;
		position: relative;
		&:before {
		  border: 4px solid transparent;
		  content: "";
		  display: block;
		  height: 0;
		  right: 5px;
		  top: 50%;
		  position: absolute;
		  width: 0;
		  border-bottom-color: $grey34;
		  margin-top: -9px;
		}
		&:after {
		  border: 4px solid transparent;
		  content: "";
		  display: block;
		  height: 0;
		  right: 5px;
		  top: 50%;
		  position: absolute;
		  width: 0;
		  border-top-color: $grey34;
		  margin-top: 1px;
		}
	  }
	  .table-data {
		display: block;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;
		width: 33%;
		padding: 0;
		color: $grey-dark1;
		font-weight: 500;
		.view-warning {
		  color: $blue;
		}
		&:first-child {
		  width: 60px;
		}
		&:nth-child(3) {
		  padding-left: 6px;
		}
		&:last-child {
		  padding-left: 16px;
		}
		.form-check-input {
		  width: 20px;
		  height: 16px;
		  margin-top: 0px;
		  vertical-align: inherit;
		  background-color: transparent;
		  background-repeat: no-repeat;
		  background-position: initial;
		  background-size: 20px;
		  border: transparent;
		  appearance: auto;
		}
	  }
	  .accordion-button {
		color: $grey-dark1;
		box-shadow: none;
		padding: 8px 10px 10px 18px;
		font-size: 14px;
		&:not(.collapsed) {
		  color: $grey-dark1;
		  box-shadow: none;
		  padding: 8px 10px 10px 18px;
		  font-size: 14px;
		  background-color: $grey31;
		  &::after {
			display: none;
		  }
		}
		&:not(.expand) {
		  &::after {
			display: none;
		  }
		}
	  }
	}
	.accordion-body {
	  padding: 0;
	  margin: 0 auto;
	  width: 90%;
	  .list-content {
		display: flex;
	  }
	  .list-items {
		display: block;
		width: 46%;
		padding-right: 40px;
		margin: 0 30px 10px 30px;
		&:last-child {
		  padding-right: 0;
		}
		.item {
		  display: flex;
		  .info {
			font-weight: 500;
			font-size: 14px;
			letter-spacing: -0.25px;
			padding: 5px 0;
			text-align: left;
			width: 80%;
			margin-right: 10px;
			&:last-child {
			  font-weight: 700;
			  width: 16%;
			}
		  }
		}
	  }
	  .synonym {
		padding: 5px 0;
		margin: 0 0 0 30px;
		text-align: left;
		font-size: 14px;
		font-weight: 500;
	  }
	  .synonym span {
		  font-weight: 700;
		  padding-left: 5px;
	  }
	}
	.form-check-input {
	  &:focus {
		border-color: none;
		outline: 0;
		box-shadow: none;
	  }
	}
	.accordion_form_main .accordion_div_main.selected button {
	  background-color: $grey31 !important;
	}
	.accordion_form_main {
	  .accordion_div_main {
		button {
		  background-color: $white;
		}
		&:nth-of-type(even) {
		  button {
			background-color: $grey6;
		  }
		}
	  }
	}
	.accordion-item {
	  &:last-of-type {
		.accordion-button {
		  &:not(.collapsed) {
			background-color: $grey31;
		  }
		}
	  }
	}
	.accordion-collapse.collapse.show {
	  background-color: $light-blue;
	}
}
@media only screen and (max-width: 1024px) {
.containment-table .panel-body .table-data {
	word-break: break-all;
}
}
@media only screen and (max-width: 820px) {
.contaminant {
	padding-bottom: 100px;
	.warning-icon {
		display: flex;
		position: relative;
		right: 10px;
		float: right;
		width: 16px;
	}
}
.containment-table {
	.panel-body {
		.table-items {
		&:first-child {
			display: none;
		}
		&:last-child {
			display: none;
			width: 4%;
		}
		&:nth-child(2) {
			width: 51%;
		}
		&:nth-child(3) {
			border-right: 0;
		}
	}
	.table-data {
		&:first-child {
			display: none;
		}
		&:nth-child(2) {
			width: 75%;
		}
		.view-warning {
			display: none;
		}
	}
	}
	.accordion-body {
		display: block;
		.list-content {
			display: block;
		}
		.list-items {
			width: 100%;
			padding-right: 0;
			margin: 10px 0;
			padding: 0 0 5px 0;
		}
		.synonym {
			margin: 0;
		}
	}
}
}