@import "../../../../assets/css/variables.scss";
.breadcrumb-list {
	display: flex;
	margin: 10px 0;
	padding: 0;
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			font-size: 15px;
			font-weight: $fw-reg;
			text-decoration: none;
			display: inline;
			color: $grey-dark1;
			a {
				color: $grey-dark1;
			}
			&:not(:last-child) {
				padding-right: 0.5rem;
				&:after {
					display: inline-block;
					padding-left: 0.5rem;
					color: $grey-dark1;
					content: "/";
				}
			}
		}
		li.active {
			color: $grey-dark1;
			font-weight: $fw-xxbold;
		}
	}
}
@media only screen and (max-width: 600px) {
	.breadcrumb-list {
		display: none;
	}
}