@import "../../../../assets/css/variables.scss";
.rs-container {
	width: 100%;
	.heading {
		background: $grey-bg1;
		display: flex;
		width: 100%;
		padding: 15px 20px;
		font-weight: $fw-xxbold;
		font-size: 17px;
		line-height: 20px;
		letter-spacing: 0.708333px;
		color: $grey-dark1;
		margin-bottom: 30px;
	}
	.selection-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.title {
			font-weight: $fw-xxbold;
			font-size: 16px;
			line-height: 24px;
			color: $grey8;
		}
		.mandatory {
			width:8px;
			height:8px;
			border-radius:50%;
			background: $red1;
			display: inline-block;
			position: relative;
    		top: -2px;
		}
		.opacity {
			opacity: 0.5;
		}
		.res-region {
			text-align: left;
		}
		.images-list {
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;
				li {
					width: 180px;
					height: 180px;
					background: $white;
					box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
					border-radius: 6px;
					align-items: center;
					justify-content: center;
					margin-right: 40px;
					display: inline-block;
					cursor: pointer;
					opacity: 0.6;
					&:last-child {
						margin-right: 0;
					}
					.pic {
						display: flex;
						height: 100%;
						align-items: center;
						justify-content: center;
						padding: 10px;
						margin-bottom: 10px;
						img {
							object-fit: none;
							max-width: 100%;
							height: auto;
						}
						.respiratorType
						{
							object-fit: contain;
						}
					}
					p.sub-title {
						text-align: center;
					}
					p.sub-title span {
						text-align: center;
						display: block;
					}
				}
				li.active {
					opacity: 1;
					cursor: default;
				}
				li.disable {
					cursor: not-allowed;
				}
				li.disable:hover {
					cursor: not-allowed;
				}
			}
		}		
		.res-type {
			text-align: left;
		}
	}
}
@media only screen and (max-width: 820px) {
	.rs-container {
		.selection-wrapper {
			.res-region {
				padding-bottom: 40px;
			}
			.images-list {
				ul {
					li {
						width: 175px;
						height: 175px;
						box-shadow: none;
						border: 1px solid $grey18;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 600px) {
	.rs-container .selection-wrapper .images-list ul li {
		width: 158px;
		margin: 0 25px 50px 0;
	}
}
@media only screen and (max-width: 360px) {
	.rs-container .selection-wrapper .images-list ul li {
		margin: 0 15px 50px 0;
	}
}