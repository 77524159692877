@import "../../../assets/css/variables.scss";
.warning-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.9);
	display: grid;
	place-items: center;
	transition: all 0.3s linear;
	visibility: visible;
	z-index: -1;
	.show-warning {
		visibility: hidden;
		z-index: 10;
	}
	.warning-container {
		background: $white;
		border-radius: 6px;
		border: 1px solid $grey18;
		width: 90vw;
		height: auto;
		max-width: 914px;
		display: grid;
		place-items: center;
		position: relative;
		padding: 30px 80px;
		top: -5rem;
		p {
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			letter-spacing: 0.708333px;
			color: $grey-dark1;
            text-align: left;
		}
		p.label-red {
			color:$red;
			font-weight: 500;
		}
		p.label-red p  {		
			font-weight: 500;
		}
		p.label-red p:first-child  {
			color:$red;		
		}
		.no-label-red {
			color: $grey-dark1;
		}
		.warning-title {
			font-weight: 700;
			font-size: 17px;
			line-height: 20px;
			letter-spacing: 0.708333px;
			color: $grey-dark1;
			margin-bottom: 20px;
			text-align: center;
		}
		.btn-container {
			margin: 10px 0 0 0;
		}
		.icon {
			padding-right: 10px;
			position: relative;
			top: -1px;
		}
		.ModalDecriptionBox {
			margin-top: 5%;
			text-align: center;
			justify-content: center;
		}
		.cta-mobile {
			display: none;
		}
	}
}
.modal-content {
    border: 0px solid rgba(0,0,0,.2);
}
.color-red-idlh {
	color:$red;
	font-weight: 500;
}
@media only screen and (max-width: 600px) {
	.warning-overlay .warning-container {
		padding: 20px;
		top: 50px;
    	position: absolute;
	}
	.warning-overlay .warning-container .btn {
		margin: 0 0 10px 0;
		width: 100%;
    	display: inline-block;
	}
	.warning-overlay .warning-container .cta-mobile {
		display: block;
	}
	.warning-overlay .warning-container .cta-desktop {
		display: none;
	}
}