@import "../../../../assets/css/variables.scss";
.resp-review {
	width: 100%;
	padding: 15px 16px;
	background: $white;
	border: 1px solid $grey18;
	box-sizing: border-box;
	.title {
		font-weight: 400;
		font-size: 20px;
		line-height: 46px;
		color: $grey-dark1;
		text-align: left;
	}
	.btn-container {
		width: 100%;
	}
	.btn.btn-secondary {
		.spinner-border {
			color: $white !important;
			width: 20px;
			height: 20px;
		}
	}
	.max-width {
		width: 100%;
	}
	.bor-bottom {
		border-bottom: 1px solid $grey18;
		padding: 0 0 10px 0;
		margin: 0 0 10px 0;
	}
	.cta-mobile {
		display: none;
	}
	.images-list {
		ul {
			list-style: none;
			margin: 5px 0 10px 0;
			padding: 0 0 15px 0;
			border-bottom: 1px solid $grey18;
			li {
				max-width: 50%;
				padding: 0 10px;
				img 
				{
					max-width: 100%;
					height: auto;
				}
				p.sub-title {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px 0;
					font-size: 14px;
					font-weight: 700;
					width: 90%;
					color: $grey-dark1;
					margin: 0 auto;
					padding-top: 8px;
					line-height: normal;
				}
				&:last-child {
					margin-right: 0;
				}
			}
			.pic_respiratorType 
				{					
					img 
					{
						max-width: 70%;
						height: auto;
					}
				}
			.no-data {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-weight: 700;
				width: 90%;
				color:$grey-dark1;
				margin: 0 auto;
				padding-top: 8px;
				line-height: normal;
			}
		}
	}
	.list-items {
		display: block;
		.item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			.info {
				font-weight: $fw-reg;
				font-size: 14px;
				letter-spacing: -0.25px;
				padding: 5px 0;
				text-align: left;
				a {
					color: $blue;
					text-decoration: none;
				}
				&:last-child {
					text-align: right;
					font-weight: $fw-xxbold;
				}
			}
		}
	}
	.warning-not-accepted {
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		border-top: 1px solid $grey18;
		padding: 20px 0 10px 0;
		margin: 20px auto;
		width:70%;
	}
	.warning-not-accepted span{
		display: block;
	}
	.product-item {
		display: block;
		margin: 0 0 10px 0;
		padding: 0 0 15px 0;
		border-bottom: 1px solid $grey18;
		.pic {
			img {
				margin-bottom: 10px;
				max-width: 100%;
				max-height: 140px;
			}
		}
		.sub-title {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 10px;
			margin-top:10px;
		}
	}
	.conditions {
		display: block;
		.text-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			.info {
				font-weight: $fw-reg;
				font-size: 14px;
				letter-spacing: -0.25px;
				padding: 5px 0;
				max-width: 72%;
				text-align: left;
				span {
					font-weight: 700;
					display: block;
				}
				&:last-child {
					text-align: right;
					font-weight: $fw-xxbold;
					max-width: 28%;
					word-wrap: break-word;
				}
			}
		}
	}
	.contact-specialist {
		display: block;
		.text-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			.contact {
				font-weight: $fw-reg;
				font-size: 14px;
				letter-spacing: -0.25px;
				padding: 5px 0;
				max-width: 50%;
				text-align: left;
				&:last-child {
					text-align: right;
					font-weight: 700;
					max-width: 50%;
				}
			}
			.email {
				font-weight: $fw-reg;
				font-size: 14px;
				letter-spacing: -0.25px;
				padding: 5px 0;
				max-width: 16%;
				text-align: left;
				a {
					text-decoration: none;
					color: $grey8;
				}
				&:last-child {
					text-align: right;
					font-weight: 700;
					max-width: 84%;
				}
			}
		}
	}
	.hlinks {
		display: block;
		a {
			display: block;
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			color: $blue;
			padding: 4px 0;
			&:hover {
				color: $blue-hov;
			}
		}
	}
}
.review-expand-icon {
	height:2em;
	width:2em;
	color: $blue;
}
.resp-acrodian {
	position: absolute;
	left: 45.48%;
	right: 45.48%;
	top: -3%;
	bottom: 91.64%;
	background: $white;
	box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
	border-radius: 18px;
	height: 2em;
}
@media only screen and (min-width: 360px) and (max-width: 480px) {
	.resp-acrodian {
		position: absolute;
		left: 45.48%;
		right: 45.48%;
		top: -10%;
		bottom: 91.64%;
		background: $white;
		box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
		border-radius: 18px;
		height: 2em;
	}
	.resp-acrodian-new {
		position: absolute;
		left: 45.48%;
		right: 45.48%;
		top: -3%;
		bottom: 91.64%;
		background: $white;
		box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
		border-radius: 18px;
		height: 2em;
	}
	.expand-resp-panel {
		position: fixed;
		left: 0%;
		right: 0%;
		top: 60%;
		bottom: 0;
		background: $white;
		box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
		border-radius: 0;
		z-index: 999999;
		width:100%;
		margin: 0 auto;
		z-index: 9;
		padding: 15px 20px 0 20px;
	}
	.expand-resp-panel.results-resp-panel {
		top: 80%;
	}
	.expand-resp-panel.contaminant-resp-panel {
		top: 70%;
	}
	.expand-resp-panel .cta-mobile {
		display: none;
	}
	.resp-review-mobile {
		padding: 15px 0;
		background: $white;
		border: 1px solid $grey18;
		box-sizing: border-box;
		height: 10%;
		position: fixed;
		top: auto;
		left: 0%;
		right: 0%;
		top: auto;
		bottom: 40px;
		box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
		border-radius: 0;
		z-index: 999;
		width:100%;
		margin: 0 auto 20px;
		.btn-container {
			width: 100%;
		}
		.max-width {
			width: 100%;
		}
		.bor-bottom {
			border-bottom: 1px solid $grey18;
			padding: 0 0 10px 0;
			margin: 0 0 10px 0;
		}
		.images-list {
			ul {
				list-style: none;
				margin: 25px 0 10px 0;
				padding: 0 0 15px 0;
				border-bottom: 1px solid $grey18;
				li {
					width: 100px;
					height: 100px;
					background: $white;
					border-radius: 6px;
					align-items: center;
					justify-content: center;
					margin-right: 15px;
					display: inline-block;
					.pic {
						img {
							object-fit: inherit;
							max-width: 100%;
							height: auto;
						}
					}
					p.sub-title {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 10px 0;
					}
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
		.list-items {
			display: block;
			.item {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				.info {
					font-weight: $fw-reg;
					font-size: 14px;
					letter-spacing: -0.25px;
					padding: 5px 0;
					max-width: 60%;
					text-align: left;
					a {
						color: $blue;
						text-decoration: none;
					}
					&:last-child {
						text-align: right;
						font-weight: $fw-xxbold;
					}
				}
			}
		}
		.product-item {
			display: block;
			margin: 0 0 10px 0;
			padding: 0 0 15px 0;
			border-bottom: 1px solid $grey18;
			.pic {
				img {
					margin-bottom: 10px;
				}
			}
			.sub-title {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 10px;
			}
		}
		.conditions {
			display: block;
			.text-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				.info {
					font-weight: $fw-reg;
					font-size: 14px;
					letter-spacing: -0.25px;
					padding: 5px 0;
					max-width: 85%;
					text-align: left;
					span {
						font-weight: 700;
						display: block;
					}
					&:last-child {
						text-align: right;
						font-weight: $fw-xxbold;
						max-width: 15%;
					}
				}
			}
		}
		.contact-specialist {
			display: block;
			.text-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				.contact {
					font-weight: $fw-reg;
					font-size: 14px;
					letter-spacing: -0.25px;
					padding: 5px 0;
					max-width: 65%;
					text-align: left;
					&:last-child {
						text-align: right;
						font-weight: 700;
						max-width: 35%;
					}
				}
				.email {
					font-weight: $fw-reg;
					font-size: 14px;
					letter-spacing: -0.25px;
					padding: 5px 0;
					max-width: 15%;
					text-align: left;
					a {
						text-decoration: none;
						color: $grey8;
					}
					&:last-child {
						text-align: right;
						font-weight: 700;
						max-width: 85%;
					}
				}
			}
		}
		.hlinks {
			display: block;
			a {
				display: block;
				font-weight: 700;
				font-size: 16px;
				line-height: 24px;
				color: $blue;
				padding: 4px 0;
				&:hover {
					color: $blue-hov;
				}
			}
		}
}
.resp-review {
	border: 0;
	padding: 0 16px;
	.images-list {
		padding-top: 10px;
	}
	.title {
		display: none;
	}
	.max-width {
		width: 90%;
	}
	.cta-desktop {
		display: none !important;
	}
	.expand-resp-panel .cta-mobile {
        display: none;
    }
	.cta-mobile {
		display: flex;
	}
	.btn-container {
		position: absolute;
		width: 100%;
		background-color: $white;
		z-index: 9;
		padding: 25px 0 10px 0;
		display: block;
		min-height: 300px;
		.btn {
			margin: 0 0 10px 0;
			&:last-child {
				margin: 0 0 20px 0;
			}
		}
	}
}
}
.pdf-header{
	width: 900px !important;
	color: $red;
	display: flex;
    text-align: left;
    padding: 50px 0px 40px 0px;
	  .header-title{
	  width: 100%;
	  font-weight: 900;
	}
}
@media only screen and (max-width: 375px) {
	.resp-review {
		.product-item {
			.sub-title {
				margin: 0;
			}
		}
		.list-items {
			.item {
				.info {
					padding: 4px 0;
				}
			}
		}
	}
	.resp-review-mobile {
		margin-bottom: 30px;
	}
}
@media only screen and (max-width: 360px) {
	.rs-container .selection-wrapper .images-list ul li {
		width: 152px;
	}
}