@import "../../../../assets/css/variables.scss";
.cartridge-selection {
	width: 100%;
	.btn-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.heading {
		background: $grey-bg1;
		display: flex;
		width: 100%;
		padding: 15px 20px;
		font-weight: $fw-xxbold;
		font-size: 17px;
		line-height: 20px;
		letter-spacing: 0.708333px;
		color: $grey-dark1;
		margin-bottom: 10px;
	}
	.products {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.images-list {
			display: flex;
			margin-top: 15px;
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;
    			flex-wrap: wrap;
				li {
					text-align: center;
					background: $white;
					border: 1px solid $white;
					cursor: pointer;
					margin: 0 0 20px 0;
					padding: 5px 10px;
					.pic {
						padding: 10px;
						img {
							max-width: 100%;
							max-height: 95px;
						}
					}
					.prod-title {
						font-weight: 700;
						font-size: 18px;
						color: $grey-dark1;
					}
					.sku {
						font-weight: 400;
						font-size: 16px;
						color: $grey-dark1;
						margin-bottom: 10px;
					}
				}
				p
				{
					font-size:20px
				}
				.product-active {
					border: 1px solid $grey9;
				}
			}
		}
	}
}
@media only screen and (max-width: 820px) {
	.cartridge-selection .products .images-list ul li {
		width: 50%;
	}
	.cartridge-selection .products .images-list ul li .pic img {
		max-height: 70px;
	}
}
@media only screen and (max-width: 480px) {
	.cartridge-selection {
		.products {
			.images-list {
				padding-bottom: 50px;
				ul {
					li {
						width: 10rem;
					}
				}
			}
		}
		.btn-container {
			padding-bottom: 160px;
		}
	}
}