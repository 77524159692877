@import "../../../../assets/css/variables.scss";
.cs-container {
  background: $white;
  border: 1px solid $grey2;
  box-sizing: border-box;
  .heading {
    font-style: normal;
    font-weight: $fw-xxbold;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.708333px;
    color: $grey-dark1;
    padding: 30px 35px 0;
    text-align: left;
  }
  .content {
    padding: 30px 35px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.708333px;
      color: $grey-dark1;
      text-align: left;
    }
    .btn-container {
      margin: auto;
      width: 310px;
    }
    .btn-container .btnlg {
      margin: 10px;
    }
    .select-options {
      display: flex;
      justify-content: space-between;
      .option {
        background-color: $white;
        margin: 0 30px 10px 0;
        padding: 10px 20px 20px 20px;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border: 1px solid $grey10;
        cursor: pointer;
        min-width: 50%;
        .option-label {
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          text-align: center;
          color: $grey-dark1;
          span {
            font-weight: 700;
            display: block;
          }
        }
        img {
          height: 100px;
        }
      }
      .option.active {
        border: 1px solid $grey-dark1;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
      }
    }
  }
}
.main-wrapper .cta-mobile {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .cs-container {
    .content {
      .btn-container {
        margin: auto;
        width: 260px;
        .btnlg {
          padding: 12px 70px;
        }
      }
    }
  }
}
@media only screen and (max-width: 952px) {
  .cs-container {
    width: 100%;
  }
}
@media only screen and (max-width: 820px) {
  .cs-container {
    .content {
      padding: 30px;
      display: block;
      .select-options {
        display: flex;
        justify-content: center;
        .option {
          margin: 0 10px 20px 10px;
        }
      }
      .btn-container {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        width: auto;
        .btnlg {
          padding: 12px 60px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .cs-container {
    margin-bottom: 40px;
    .content {
      padding: 20px;
      display: block;
      .select-options {
        flex-flow: row wrap;
        .option {
          margin: 0 0 20px 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .option-label {
            line-height: normal;
            text-align: left;
          }
        }
      }
      .btn-container {
        display: flex;
        justify-content: center;
        left: 0;
        width: 100%;
        background-color: $white;
        bottom: 0;
        z-index: 9;
        padding: 25px 0 10px 0;
        box-shadow: 0px -4px 6px rgb(0 0 0 / 10%);
        border: 1px solid $grey18;
        .btnlg {
          margin: 0 15px 20px 0;
          width: 170px;
          padding: 12px 40px;
          &:last-child {
            margin: 0 0 20px 0;
          }
        }
      }
      .cta-desktop {
        display: none;
      }
    }
  }
  .main-wrapper .cta-mobile {
    display: block;
    margin-bottom: 100px;
  }
  .main-wrapper .cta-mobile > div:last-child {
    margin-top: 10px;
  }
}