@import "../src/assets/css/variables.scss";
@import "../src/assets/fonts/fonts.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a, a:hover {
  outline: 0;
  text-decoration: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $grey-dark7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
iframe:focus {
  outline: none;
}
iframe[seamless] {
  display: block;
}
iframe#iFrameCalculatorApp {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    min-height: 500px;
}
.main-wrapper {
	font-family: 'Honeywell Sans Web';
	width: 96%;
	margin: 30px auto;
	.main-heading {
		display: flex;
		padding-bottom: 30px;
		font-style: normal;
		font-weight: 400;
		font-size: 2.5em;
		color: $grey-dark1;
	}
}
.btn {
  margin: auto 10px;
  padding: 12px 48px;
  border: 2px solid $blue;
  border-radius: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  cursor: pointer;
  text-transform: uppercase;
  min-width: 190px;
}
.btnlg {
  margin: auto 10px;
  padding: 12px 90px;
  border: 2px solid $blue;
  border-radius: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  cursor: pointer;
  text-transform: uppercase;
}
.btnmd {
  margin: auto 10px;
  padding: 12px 80px;
  border: 2px solid $blue;
  border-radius: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  cursor: pointer;
  text-transform: uppercase;
}
.btnsm {
  margin: auto 10px;
  padding: 12px 20px;
  border: 2px solid $blue;
  border-radius: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  cursor: pointer;
  text-transform: uppercase;
  min-width: 140px;
}
.btn-disabled {
  color: $grey1;
  background-color: $grey2;
  border: 2px solid $grey2;
  cursor: not-allowed;
  pointer-events: all !important;
  &:hover {
		color: $grey1;
	}
}
.btn-primary {
	color: $blue;
	background-color: $white;
	&:hover {
		color: $blue-hov;
		background-color: $white;
    border: 2px solid $blue-hov;
	}
  &:focus {
    box-shadow: none;
  }
}
.btn-secondary {
	color: $white;
	background-color: $blue;
	&:hover {
		color: $white;
		background-color: $blue-hov;
		border: 2px solid $blue-hov;
	}
  &:focus {
    box-shadow: none;
  }
}
.btn-default {
  color: $white;
  background-color: $grey-dark1;
  border: 2px solid $grey-dark1;
	&:hover {
		color: $white;
		background-color: $grey-dark1;
		border: 2px solid $grey-dark1;
	}
  &:focus {
    box-shadow: none;
  }
}
.scroll-to-top.js-to-top.d-inline-block {
  display: none !important;
}
div.breadcrumb
{
  display: none !important;
}
@media only screen and (min-width: 1025px) {
  .main-wrapper {
    .col-md-9.col-sm-12 {
      flex: 0 0 auto;
      width: 70%;
    }
    .col-md-3.col-sm-12 {
      flex: 0 0 auto;
      width: 30%;
    }
  }
}
@media only screen and (min-width: 1281px) {
  .main-wrapper {
    .col-md-9.col-sm-12 {
      flex: 0 0 auto;
      width: 73%;
    }
    .col-md-3.col-sm-12 {
      flex: 0 0 auto;
      width: 27%;
    }
  }
}
@media only screen and (max-width: 952px) {
  .main-wrapper {
    width: 94%;
  }
}
@media only screen and (max-width: 820px) {
  .main-wrapper {
    .col-md-9.col-sm-12 {
      flex: 0 0 auto;
      width: 56%;
    }
    .col-md-3.col-sm-12 {
      flex: 0 0 auto;
      width: 44%;
    }
    .main-heading {
      padding-bottom: 20px;
      font-size: 2.0em;
    }
  }
}
@media only screen and (max-width: 480px) {
  .main-wrapper {
    width: 92%;
    .main-heading {
      font-size: 22px;
      padding-bottom: 20px;
    }
    .col-md-9.col-sm-12 {
      width: 100%;
    }
    .col-md-3.col-sm-12 {
      width: 100%;
    }
  }
  .btn {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
}