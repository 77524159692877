@import "../../../../../assets/css/variables.scss";
.terms-sec {
    display: block;
    padding-top: 20px;
    border-top: 1px solid $grey32;
    padding: 10px 20px 10px 20px;
	margin: 0 auto 10px auto;
    .subhead {
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.708333px;
        color: $grey-dark1;
        margin-bottom: 20px;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: left;
    }
}
.logo-container-terms {
    align-items: center;
    padding: 5rem 0 3rem 0;
    margin-left: 2rem;
    display: none;
    .logo-link {
        font-size: 70px;
        line-height: 38px;
        width: 20%;
    }
    .logoPdf {
        width: 100%;
        float: left;
    }
    .tagline {
        padding: 7px 0 7px 15px;
        margin: 5px 0 0 15px;
        border-left-width: 1px;
        border-left-style: solid;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: .5px;
        line-height: normal;
    }
    h2 {
        border-left-color: $grey2;
    }
}
.warning-pdf {
    .content {
        padding:5px;
        align-items: center;
    }
}
.warning-subhead {
    display: none;
    font-style: normal;
    justify-content: center;
    justify-items: center;
    font-weight: 700;
    font-size: 17px;
    padding-top: 20px;
    width: 98%;
	padding: 0 0 10px 0;
	margin: 0 auto 10px auto;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.708333px;
    color: $grey-dark1;
    margin-bottom: 20px;
}
.download-pdf-table {
	display: none;
	table {
		width: 100%;
	}
	th {
		border: 1px solid $grey2;
		padding: 8px;
        word-spacing: 2px;
	}
	td {
		vertical-align: text-top;
		border: 1px solid $grey2;
		padding: 18px;
		font-size: 14px;
        width: 50%;
        word-spacing: 1px;
        letter-spacing: 0.1px;
	}
    td ul {
        margin-left: -25px;
	}
	.table-header {
		background-color: $red;
		text-align: center;
		font-weight: 700;
		color: $white;
		text-transform: uppercase;
	}
}
@media only screen and (max-width: 600px) {
	.terms {
        width: 90%;
        padding-bottom: 100px;
    }
    .terms-sec {
        padding-bottom: 270px;
    }
}