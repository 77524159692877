@import "../../../../assets/css/variables.scss";
.respirator-steps {
	ul {
		display: flex;
		margin: auto;
		overflow: hidden;
		padding: 0;
		position: relative;
		text-align: center;
		z-index: 1;
	}
	li {
		list-style-type: none;
		color: $grey-dark1;
		line-height: normal;
		flex: 1;
		font-weight: 400;
		font-size: 16px;
		line-height: 25px;
		text-align: center;
		text-transform: capitalize;
		position: relative;
		padding: 0 2%;
		&:before {
			content: counter(step);
			width: 38px;
			height: 38px;
			display: block;
			background: $white;
			border-radius: 50%;
			margin: 0 auto 10px;
			position: relative;
			z-index: 1;
			text-indent: -9999999px;
			border: 6px solid $grey13;
		}
		&:after {
			content: "";
			width: 100%;
			height: 8px;
			background: $grey13;
			position: absolute;
			left: -50%;
			top: 15px;
			z-index: -1;
		}
		&:first-child {
			&:after {
				content: none;
			}
		}
	}
	li.complete {
		&:before {
			background: $blue;
			border: 6px solid $blue;
			color: $white;
		}
		&:after {
			background: $blue;
		}
	}
}

@media only screen and (max-width: 480px) {
    .respirator-steps {
	ul {
		display: block;
		li {
			&:before {
				float: none;
				display: inline-block;
				margin-right: 10px;
				text-align: center;
				margin-left: 0;
			}
		}
	}
	li {
		flex: none;
		clear: both;
		text-align: left;
		padding: 0;
		margin-left: 0;
		min-height: 2.2em;
		span {
			white-space: nowrap;
		}
		&:after {
			content: "";
			width: 6px;
			height: 100%;
			position: absolute;
			left: 16px;
			top: -50%;
			z-index: -1;
		}
	    }
    }
}