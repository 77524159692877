@import "../../../../assets/css/variables.scss";
.rc-results {
	width: 100%;
	.btn-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.bor-bottom {
		border-bottom: 1px solid $grey32;
		padding: 0 0 10px 0;
		margin: 0 0 10px 0;
	}
	.heading {
		background: $grey-bg1;
		display: flex;
		width: 100%;
		padding: 15px 20px;
		font-weight: $fw-xxbold;
		font-size: 17px;
		line-height: 20px;
		letter-spacing: 0.708333px;
		color: $grey-dark1;
		margin-bottom: 15px;
	}
	.form-group {
		width: 330px;
		margin: 0 auto;
		padding-top: 20px;
		label {
			font-weight: 800;
			font-size: 16px;
			line-height: 24px;
			color: $grey8;
			text-align: left;
			padding: 0 0 6px 0;
			display: block;
			span {
				font-weight: 500;
			}
		}
        .small {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: $grey9;
            text-align: left;
        }
		.form-control {
			border-radius: 0;
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 12px 12px 12px 16px;
			gap: 8px;
			height: 48px;
			background: $white;
			border: 1px solid $grey2;
			flex: none;
			order: 1;
			align-self: stretch;
			flex-grow: 0;
			appearance: none;
			&:focus {
				border-color: $grey2;
				outline: 0;
				box-shadow: none;
			}
		}
		.angle-down-arrow {
			float: right;
			margin-top: -40px;
			margin-right: 0.5em;
			pointer-events: none;
			background-color: transparent;
			color: $grey34;
		}
		.angle-down-icon {
			width: 1.9em;
			height: 1.9em;
			stroke-width: 0;
		}
	}
	.region-list {
		display: flex;
		align-items: center;
		justify-content: center;
		ul {
			list-style: none;
			margin: 25px 0 10px 0;
			padding: 0 0 15px 0;
			li {
				align-items: center;
				justify-content: center;
				padding: 10px;
				width: 50%;
				display: inline-block;
				.pic {
					text-align: center;
					img {
						object-fit: inherit;
						max-width: 100%;
						height: auto;
					}
				}
				p.sub-title {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px 0;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.your-cartridge {
		display: flex;
		width: 100%;
		margin: 30px auto 0;
		.product {
			display: flex;
			&:first-child {
				width: 35%;
				justify-content: center;
			}
			&:last-child {
				padding: 0 0 0 20px;
				text-align: left;
				width: 65%;
			}
			img {
				max-width: 100%;
			}
			.details {
				display: block;
				margin-top: 5px;
				p {
					font-weight: 500;
					font-size: 16px;
					line-height: normal;
					text-align: left;
					margin-bottom: 5px;
				}
			}
		}
	}
	.estimated-service {
		border-bottom: 1px solid $grey32;
		margin-bottom: 10px;
		padding-bottom: 10px;
		text-align: center;
		.title {
			font-weight: 700;
			font-size: 28px;
			line-height: 46px;
			color: $grey-dark8;
		}
		.sub-title {
			font-weight: 700;
			font-size: 24px;
			line-height: 22px;
			color: $grey8;
			margin: 10px 0;
			background-color: $grey31;
			display: inline-block;
			padding: 10px 16px;
		}
		p {
			font-size: 16px;
			line-height: 24px;
			color: $grey8;
			span {
				font-weight: 700;
			}
		}
	}
	.product-details {
		border-bottom: 1px solid $grey32;
		margin-bottom: 10px;
		padding-bottom: 10px;
		text-align: center;
		.sub-heading {
			font-weight: 700;
			font-size: 20px;
			line-height: 46px;
			color: $grey-dark8;
			margin-bottom: 10px;
		}
		.sub-title {
			font-weight: 800;
			font-size: 18px;
			line-height: 22px;
			color: $grey8;
		}
	}
	.content-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
		padding-bottom: 10px;
		.title {
			font-weight: 700;
			font-size: 20px;
			line-height: 46px;
			color: $grey-dark8;
			text-align: center;
		}
		.edit-icon {
			display: none;
		}
		.column {
			width: 50%;
			border-right: 1px solid $grey33;
			padding-right: 15px;
			text-align: left;
			&:last-child {
				border-right: 0;
				padding: 0 0 0 15px;
				text-align: left;
			}
			.cal-details {
				display: block;
				.text-wrapper {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					&:nth-of-type(even) {
						background-color: $grey6;
					}
					.info {
						font-weight: $fw-reg;
						font-size: 14px;
						letter-spacing: -0.25px;
						padding: 7px 10px 7px 10px;
						text-align: left;
						&:last-child {
							text-align: right;
							font-weight: 700;
							max-width: 80%;
                            padding-right: 10px;
						}
						span {
							font-weight: 700;
						}
					}
				}
			}
		}
	}
	.content-wrapper-top {
		display: none;
		flex-wrap: wrap;
		margin-bottom: 10px;
		padding-bottom: 10px;
		.title {
			font-weight: 400;
			font-size: 20px;
			line-height: 46px;
			color: $grey-dark8;
			text-align: center;
		}
		.edit-icon {
			display: none;
		}
		.column {
			width: 50%;
			border-right: 1px solid $grey33;
			padding-right: 15px;
			text-align: left;
			&:last-child {
				border-right: 0;
				padding: 0 0 0 15px;
				text-align: left;
			}
			.cal-details {
				display: block;
				.text-wrapper {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					&:nth-of-type(even) {
						background-color: $grey6;
					}
					.info {
						font-weight: $fw-reg;
						font-size: 14px;
						letter-spacing: -0.25px;
						padding: 7px 0 7px 10px;
						max-width: 40%;
						text-align: left;
						&:last-child {
							text-align: right;
							font-weight: 700;
							max-width: 60%;
                            padding-right: 10px;
						}
						span {
							font-weight: 700;
							display: block;
						}
					}
				}
			}
		}
	}
}
.terms2 {
	display: none;
}
.subhead {
	font-size: 14px;
	font-weight: 600;
}
.logo-container1 {
	align-items: center;
    padding: 5rem 0 3rem 0;
	display: none;
	.logo-link {
	  font-size: 70px;
	  line-height: 38px;
	  width: 20%;
	}
	.logoPdf {
		width: 100%;
		float: left;
	}
	.tagline {
	  padding: 7px 0 7px 15px;
	  margin: 5px 0 0 15px;
	  border-left-width: 1px;
	  border-left-style: solid;
	  text-transform: uppercase;
	  font-size: 15px;
	  font-weight: 900;
	  letter-spacing: .5px;
	  line-height: normal;
	}
	h2 {
	  border-left-color: $grey2;
	}
}
.pdf-warning {
	display: none;
	margin: 15px;

	padding-right: 15px;
}
.warning-headline {
	font-size: 20px;
	font-weight: 800;
	text-align: center;
}
.icon-warning {
	padding: 10px;
}
.pdf-show {
	display: none;
}

@media only screen and (max-width: 1024px) {
	.rc-results {
		.your-cartridge {
			width: 100%;
		}
	}
}
@media only screen and (min-width: 1024px) {
	.rc-results {
		.your-cartridge {
			.product {
				img {
					max-height: 110px;
				}
			}
		}
	}
}
@media only screen and (max-width: 820px) {
	.rc-results {
		.content-wrapper {
			.column {
				width: 100%;
				border-right: 0;
				padding: 0;
				&:last-child {
					padding: 0;
				}
				.cal-details {
					.text-wrapper {
						.info {
							max-width: inherit;
							&:last-child {
								max-width: inherit;
							}
						}
					}
				}
			}
			.title {
				text-align: left;
			}
			.edit-icon {
				float: right;
				position: relative;
				top: 17px;
				cursor: pointer;
				display: block;
			}
		}
		.your-cartridge {
			width: 100%;
			margin:10px;
		}
	}	
}
@media only screen and (max-width: 600px) {
	.rc-results {
		.content-wrapper {
			.column {
				width: 100%;
				border-right: 0;
				padding-right: 0;
				&:last-child {
					padding: 0;
				}
			}
		}
		.your-cartridge {
			.product {
				img {
					max-height: 110px;
				}
			}
		}
	}
}