@import "../../../../assets/css/variables.scss";
.tc-container {
  width: 940px;
  background: $white;
  border: 1px solid $grey18;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 0 auto;
  .content {
    padding: 30px 46px;
    .heading {
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.708333px;
      color: $grey-dark1;
      margin-bottom: 20px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.708333px;
      color: $grey-dark1;
      text-align: left;
    }
    .btn-container {
      margin: 30px 0 0 0;
    }
    .checkbox {
      width: 100%;
      margin: 15px auto;
      position: relative;
      display: block;
      input[type="checkbox"] {
        width: auto;
        opacity: 0.00000001;
        position: absolute;
        left: 0;
        margin-left: -20px;
        &:checked~label {
          &::before {
            color: $white;
            background: $black;
            border: 1px solid $black;
          }
          &::after {
            -webkit-transform: rotate(-45deg) scale(1);
            transform: rotate(-45deg) scale(1);
          }
        }
        &:focus+label {
          &::before {
            outline: 0;
          }
        }
      }
      label {
        position: relative;
        min-height: 30px;
        display: block;
        padding-left: 30px;
        margin-bottom: 0;
        font-weight: normal;
        cursor: pointer;
        text-align: left;
        &:before {
          content: '';
          position: absolute;
          left: -2px;
          top: 0;
          margin: 5px;
          width: 16px;
          height: 16px;
          transition: transform 0.28s ease;
          border: 1px solid $grey1;
          background: $white;
        }
        &:after {
          content: '';
          display: block;
          width: 10px;
          height: 5px;
          border-bottom: 2px solid $white;
          border-left: 2px solid $white;
          -webkit-transform: rotate(-45deg) scale(0);
          transform: rotate(-45deg) scale(0);
          transition: transform ease 0.25s;
          will-change: transform;
          position: absolute;
          top: 10px;
          left: 7px;
        }
        span {
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    }
    .cta-mobile {
      display: none;
    }
  }
}
@media only screen and (max-width: 952px) {
  .tc-container {
    width: 100%;
  }
}
@media only screen and (max-width: 820px) {
  .tc-container .content {
    padding: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .tc-container {
    margin-bottom: 100px;
    .content {
      padding: 20px;
      .checkbox {
        label {
          span {
            transform: translateY(-32%);
          }
        }
      }
      .btn-container {
        padding-top: 20px;
        .btn {
          margin: 0 0 10px 0;
          &:last-child {
            margin: 0 0 20px 0;
          }
        }
      }
      .cta-mobile {
        display: block;
      }
      .cta-desktop {
        display: none;
      }
    }
  }
}