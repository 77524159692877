@import "../../../../assets/css/variables.scss";
.particles {
	width: 340px;
	margin-top:12%;
	.title {
		font-weight: 800;
		font-size: 16px;
		line-height: 24px;
		color: $grey8;
		text-align: left;
		padding-bottom: 12px;
		.mandatory {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: $red1;
			display: inline-block;
			position: relative;
			top: -2px;
			left: 10px;
		}
	}
	.btn-container {
		width: 100%;
		display: flex;
		.btn {
			padding: 10px 48px;
			min-width: 140px;
			&:first-child {
				border-right: 0;
			}
			
		}
	}
	.form-group {
		label {
			font-weight: 800;
			font-size: 16px;
			line-height: 24px;
			color: $grey8;
			text-align: left;
			padding: 0 0 6px 0;
			display: block;
			span {
				font-weight: 500;
			}
		}
        .small {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: $grey9;
            text-align: left;
        }
		.form-control {
			border-radius: 0;
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 12px 12px 12px 16px;
			gap: 8px;
			height: 48px;
			background: $white;
			border: 1px solid $grey2;
			flex: none;
			order: 1;
			align-self: stretch;
			flex-grow: 0;
			&:focus {
				border-color: $grey2;
				outline: 0;
				box-shadow: none;
			}
		}
	}
}
.btn-disabled {
	cursor:not-allowed !important;
	color:$blue;
	background-color: $grey18;
	&:hover { 
		cursor:not-allowed !important;
		color:$blue;
		background-color: $grey18;
	}
}
.btn-disable {
	color: $grey1;
	background-color: $grey2;
	border: 2px solid $grey2 !important;
	cursor: not-allowed !important;
	pointer-events: all !important;
	&:hover {
		  color: $grey1;
	}
}
@media only screen and (max-width: 820px) {
    .particles {
        width: 100%;
		padding:60px 0 20px 0;
    }
}