@import '../../../assets/css/variables.scss';
.search-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p {
        padding-top: 6px;
    }
    .btn {
        margin: 0;
        padding: 5px 17px;
        border: 2px solid $blue;
        border-radius: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.5px;
        cursor: pointer;
        text-transform: uppercase;
        min-width: 110px;
        &:focus {
            border: 2px solid transparent;
        }
    }
    .btn-disabled{
        color: #707070;
        background-color: #d0d0d0;
        border: 2px solid #d0d0d0;
        cursor: not-allowed;
        pointer-events: all !important;
    }
    .btn-disabled:hover{
        color: #707070;
    }
    .form-control {
        border-radius: 0;
        &:focus {
            border-color: $grey2;
            outline: 0;
            box-shadow: none;
        }
        &::-webkit-input-placeholder {
            color: $grey21;
            font-style: italic;
        }
    }
    .input-group {
        width: 42%;
    }
}

.search-block-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        padding-top: 6px;
    }
    .btn {
        margin: 0;
        padding: 5px 17px;
        border: 2px solid $blue;
        border-radius: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.5px;
        cursor: pointer;
        text-transform: uppercase;
        min-width: 110px;
        &:focus {
            border: 2px solid transparent;
        }
    }
    .btn-disabled{
        color: #707070;
        background-color: #d0d0d0;
        border: 2px solid #d0d0d0;
        cursor: not-allowed;
        pointer-events: all !important;
    }
    .btn-disabled:hover{
        color: #707070;
    }
    .form-control {
        border-radius: 0;
        &:focus {
            border-color: $grey2;
            outline: 0;
            box-shadow: none;
        }
        &::-webkit-input-placeholder {
            color: $grey21;
            font-style: italic;
        }
    }
    .input-group {
        width: 42%;
    }
}
@media only screen and (max-width: 820px) {
    .search-block {
        .input-group {
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .search-block-status {
        .input-group {
            width: 100%;
            margin-bottom: 10px;
        }
        display: block;
        p {
            text-align: left;
        }
    }
}
@media only screen and (max-width: 600px) {
    .search-block {
        .input-group {
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .search-block-status {
        .input-group {
            width: 100%;
            margin-bottom: 10px;
        }
        display: block;
        p {
            text-align: left;
        }
    }
}