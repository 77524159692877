@import "../../../../../assets/css/variables.scss";
.pagination {
	width: 100%;
	padding: 5px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid $grey2;
	color: $grey-dark1;
	font-weight: 500;
	font-size: 16px;
	.rows-pages {
		display: flex;
		align-items: center;
		order: 3;
		.form-select {
			padding: .475rem 2.25rem .475rem .75rem;
			border: 1px solid $grey2;
			border-radius: 0;
			&:focus {
				outline: 0;
				box-shadow: none;
			}
		}
		span {
			padding-right: 10px;
			min-width: 130px;
		}
	}
	.pages-count {
		top: 8px;
		position: relative;
		order: 1;
	}
	.page-numbers {
		order: 2;
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				margin: 2px;
				display: inline-block;
				cursor: pointer;
				padding: 8px 14px;
				color: $grey-dark1;
				font-size: 16px;
				a {
					color: $grey-dark1;
					text-decoration: none;
					&:hover {
						color: $blue;
					}
				}
			}
			li.arrow {
				vertical-align: middle;
				font-size: 16px;
				text-decoration: none;
				display: inline-block;
				color: $grey-dark1;
				top: -1px;
				position: relative;
			}
			li.active {
				border: 1px solid $blue;
			}
			li.arrow-disabled a{				
				color: $grey1 !important;
				cursor: default;
				display: none;
			}
		}
	}
}
@media only screen and (max-width: 820px) {
	.pagination {
		flex-flow: row wrap;
		justify-content: space-between;
		.pages-count {
			order: 1;
		}
		.rows-pages {
			order: 2;
		}
		.page-numbers {
			order: 3;
			width: 100%;
			padding-top: 10px;
		}
	}
}
@media only screen and (max-width: 480px) {
	.pagination {
		justify-content: center;
		font-size: 14px;
		margin-bottom: 150px;
		.rows-pages {
			span {
				padding-right: 5px;
				min-width: 125px;
			}
		}
	}
}
@media only screen and (max-width: 360px) {
	.pagination {
		padding: 5px 10px;
		font-size: 14px;
		.rows-pages {
			span {
				padding-right: 5px;
				min-width: 125px;
			}
		}
	}
}