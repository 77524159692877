@import "../../../../../assets/css/variables.scss";
.rc-results-wrapper {
  font-family: Arial, Helvetica, sans-serif !important;
  display: none;
  font-size:10px;
 .pdf-header {
  width: 900px !important;
  color: $red;
    .header-title {
      width: 100%;
      font-weight: 900;
   }
 }
}
#rc-resultsPdf-page1,
#rc-resultsPdf-page2 {
  width: 900px;
  margin-left: 25px;
  padding: 0px 50px 0px 50px;
  .pdf-hide {
    display: none;
  }
  .pdf-footer {
    width: 900px;
    display: flex;
    padding: 65px 65px 50px 0px;
    .contact-info {
      float: left;
      line-height: 5px;
      text-align: left;
      margin-top: 30px;
      word-spacing: 5px;
      font-size: 12px;
    }
    .contact-info a {
      color: $grey-dark1;
    }
    .contact-info .info-text {
      word-spacing: 1px;
      letter-spacing: 0.9px;
    }
    .honeywell-logo {
      margin-left: auto;
      margin-top: auto;
      .logo-footer{
        width: 10rem;
        opacity: 0.5;
      }
    }
  }
  .expected-vapor-title, 
  .working-condition-title,
  .region-respirator-title,
  .cartage-selection-title {
    text-transform: uppercase;
    word-spacing: 2px;
    letter-spacing: 1.1px;
  }
  .info {
    letter-spacing: 0.5px !important;
  }
  .source-info {
    letter-spacing: 0.7px;
    font-weight: 500;
    font-size: 14px !important;
  }
  .see-below {
    letter-spacing: 1px;
    font-weight: 500;
    color: $black;
    font-size: 12px;
  }
}

.table-for {
  display: flex;
  width: 900px;
  border: 1px solid $grey10;
  margin: 0px 0px 0px -50px;
  .colum-terms {
    width: fit-content !important;
  }
}
#rc-resultsPdf-page1 {
  .pdf-page1-sec3 .pdf-w50 {
    width: 50%;
  }
  .pdf-page1-sec3 .pb2 {
    padding-bottom: 20px;
  }
  .word-space1 {
    word-spacing: -5px;
  }
  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-bottom: 10px;
    .title {
      font-weight: 600;
      font-size: 17px;
      line-height: 46px;
      color: $grey-dark8;
      text-align: center;
    }
    .edit-icon {
      display: none;
    }
    .column {
      width: 50%;
      border-right: 1px solid $grey33;
      padding-right: 15px;
      text-align: left;
      &:last-child {
        border-right: 0;
        padding: 0 0 0 15px;
        text-align: left;
      }
      .cal-details {
        display: block;
        .text-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          &:nth-of-type(even) {
            background-color: $grey6;
          }
          .info {
            font-weight: $fw-reg;
            font-size: 14px;
            letter-spacing: -0.25px;
            padding: 7px 10px 7px 10px;
            text-align: left;
            &:last-child {
              text-align: right;
              font-weight: 700;
              max-width: 80%;
              padding-right: 10px;
            }
            span {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .pdf-show {
    display: flex !important;
  }
  .pdf-page1-sec2 .region-list .pic {
    img {
      max-height: 100px;
    }
  }
  .pdf-page1-sec2 .region-list .emea-text {
    word-spacing: 1px;
    letter-spacing: 1.5px;
  }
}
#rc-resultsPdf-page1 #respiratorSelection {
  display: none !important;
}
.terms {
  margin: 25px;
}
.pdf-warning ul {
  margin-left: -25px;
}
#rc-resultsPdf-page1 {
	.content-wrapper {
		.column {
			.cal-details {
				.text-wrapper {
					.info {
						font-size: 12px;
						font-weight: 500;
						letter-spacing: -0.25px;
						line-height: 14px;
						padding: 7px 0px 5px 8px;
						text-align: left;
						&:last-child {
							max-width: 80%;
              font-weight: 500;
						}
					}
				}
			}
		}
	}
	.your-cartridge {
    margin: 15px 0 0 0;
		.product {
			.details p {
				font-size: 12px;
			}
      img {
        max-height: 90px;
      }
      &:first-child {
        width: 28%;
      }
      &:last-child {
        width: 72%;
      }
		}
	}
}